import React from "react"
// import Icon1 from "../../../components/assets/img/pages/landing/new/icon-1.png"
// import Icon2 from "../../../components/assets/img/pages/landing/new/icon-2.png"
// import Icon3 from "../../../components/assets/img/pages/landing/new/icon-3.png"
// import Icon4 from "../../../components/assets/img/pages/landing/new/icon-4.png"
// import Icon5 from "../../../components/assets/img/pages/landing/new/icon-5.png"
// import Icon6 from "../../../components/assets/img/pages/landing/new/icon-6.png"

const seventhSectionData = [
    {
        Title: "Crie seu perfil",
        Text: (
            <p className="blue-font">
                Para participar da rede Gifthy é necessário informar apenas o
                seu nome, e-mail e criar uma senha.
                <br />
                Você também pode acessar por conta Google ou Facebook.
                Depois do cadastro efetuado com sucesso, estará
                tudo pronto! Bora aproveitar.
            </p>
        ),
    },
    {
        Title: "Defina suas preferências",
        Text: (
            <p className="blue-font">
                Acessando à lista de produtos disponíveis, informe o que você
                gostaria de receber de presente. É o suficiente para que seus
                amigos saibam o que lhe deixará feliz.
                <br />É muito fácil e divertido.
            </p>
        ),
    },
    {
        Title: "Convide amigos para participarem",
        Text: (
            <p className="blue-font">
                Chame seus amigos para formar a sua rede. Você só precisa buscar
                pelo nome deles, no campo "pesquisar".
                <br />
                Aceito o convite, vocês já podem se divertir juntos!
            </p>
        ),
    },
    {
        Title: "Escolha o endereço",
        Text: (
            <p className="blue-font">
                O presente será entregue no endereço que você escolher. Não se
                preocupe.
                <br />
                Todos os seus dados estão seguros.
                <br />
                Só você e a logística do Gifthy conhecerão
            </p>
        ),
    },
    {
        Title: "Tudo pronto!",
        Text: (
            <p className="blue-font">
                Agora você não esquecerá mais dos aniversários ou de qualquer
                outro evento que você considere importante. Chega de sorrisos
                amarelos ou desculpas! Com o Gifthy você vai lembrar de todas as
                datas que merecem uma atenção especial e vai aproveitar
                presenteando com mais praticidade, segurança, economia e
                agilidade.
                <br />
                Demonstre todo o seu carinho. O Gifthy encontrará o presente
                ideal para você!
            </p>
        ),
    },
]

const SeventhSection = () => {
    return (
        <section className="seventh" id="por-que">
            <div className="grid">
                {seventhSectionData.map((item, index) => (
                    <div key={index.toString()} className="item">
                        <div className="index-box">
                            <span className="white-font font-bold">{index + 1}.</span>
                        </div>
                        <span className="yellow-font">{item.Title}</span>
                        {item.Text}
                    </div>
                ))}
            </div>
        </section>
    )
}

export default SeventhSection
